<template>
  <div class="container pd100">
    <van-search
      v-model="keyword"
      class="search"
      show-action
      placeholder="搜一搜，找到你想要的校区"
    >
      <template #action>
        <div class="area">
          <van-icon class="mr5" class-prefix="icon" name="MapOutline1" />
          <span class="area-txt" @click="onArea">{{ area }}</span>
          <van-icon name="arrow-down" />
        </div>
      </template>
    </van-search>
    <!-- <van-swipe :autoplay="3000" indicator-color="white" class="home-swiper">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <img :src="item.picUrl" :alt="item.title" />
      </van-swipe-item>
    </van-swipe>-->
    <div class="main">
      <van-list v-model="listLoading" class="list" offset='50' :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <van-row>
          <van-col v-for="(item,index) in list" :key="index" span="24">
            <div class="list-item" @click="onDetailClick(item.studio_id)">
              <div class="list-item-media">
                <van-image
                  class="list-item-img"
                  :src="item.studio_cover"
                  :alt="item.studio_name"
                  radius='2px'
                />
              </div>
              <div class="list-item-inner">
                <div class="list-item-title van-ellipsis">{{ item.studio_name }}</div>
                <div class="list-item-desc">
                  <div class="list-item-desc-hd">
                    <div class="list-item-desc-hd-span">{{ item.studio_address }}</div>
                  </div>
                  <div v-if="item.studio_distance" class="list-item-desc-bd">
                    {{ item.studio_distance }}
                  </div>
                </div>
                <div class="list-item-rate van-hairline--bottom">
                  <van-rate
                    :value="item.studio_rate | formatNumber"
                    size="12"
                    readonly
                    allow-half
                    void-icon="star"
                    void-color="#eee"
                    :color="globalData.theme.color1"
                  />
                </div>
                <div class="list-item-more">
                  <div class="list-item-desc-hd-span van-ellipsis">{{ item.studio_tags }}</div>
                  <!-- <div class="list-item-more-p">
                    <span class="list-item-more-tag" type="warning">试</span>在线预约试听
                  </div> -->
                  <!-- <div class="list-item-more-p">
                    <span
                      class="list-item-more-tag"
                      :style="{'background':globalData.theme.color}"
                    >领</span>新用户可免费领取体验卡
                  </div> -->
                </div>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-list>
    </div>
    <copyright v-show="!listLoading" />
    <!-- 校区区域选择 -->
    <van-popup v-model="showPicker" position="bottom" round>
      <van-tree-select
        :items="areaList"
        :active-id.sync="areaActiveId"
        :main-active-index.sync="areaActiveIndex"
        @click-nav="onClickNav"
        @click-item="onAreaClicked"
      />
    </van-popup>
    <!-- 底部导航栏 -->
    <Tabbar/>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Search, Rate, Tag, TreeSelect } from 'vant'
import PageMixin from '@/mixins/page'
import { debounce } from '@/common/util'
import storage from 'good-storage'
import { getLocation } from '@/common/wx'
import Copyright from '@/components/Copyright'
import Tabbar from '@/components/Tabbar'

export default {
  name: 'Home',
  components: {
    Copyright,
    Tabbar:Tabbar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Search.name]: Search,
    [Rate.name]: Rate,
    [Tag.name]: Tag,
    [TreeSelect.name]: TreeSelect
  },
  computed: {
  },
  mixins: [PageMixin],
  data() {
    return {
      active: 0,
      images: [],
      value: '',
      banner: [],
      keyword: '',
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      // 校区区域选择
      area: storage.get('wedots_area', '全部'),
      showPicker: false,
      areaList: [],
      areaActiveId: '0',
      areaActiveIndex: 0,
      location: {
        latitude: '',
        longitude: ''
      },
      activeBar:0
    }
  },
  watch: { 
    keyword(val) {
      this.search()
    },
  },
  created() {
    if(!sessionStorage.getItem('homeworkStatus')){
      sessionStorage.setItem('homeworkStatus',0)
    }
    this.getProfileCampus()
    this.queryAreaList()
  },
  methods: {
    wechatConfigSuccessCallback() {
      getLocation().then(res => {
        this.location.latitude = res.latitude
        this.location.longitude = res.longitude
        this.pageCurrent = 1
        this.onPage()
      })
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      const params = {
        'per-page': this.pageSize,
        page: this.pageCurrent
      }
      this.keyword && (params.q = this.keyword)
      this.area !== '全部' && (params.district = this.area)
      this.location.longitude && (params.lng = this.location.longitude)
      this.location.latitude && (params.lat = this.location.latitude)

      this.$api.campus_index(params).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        console.log(res._meta.currentPage,this.list)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    search: debounce(function() {
      this.pageCurrent = 1
      this.onPage()
    }, 300),
    onDetailClick(id) {
      this.$router.push({ path: '/studio-detail', query: { id }})
    },
    queryAreaList() {
      this.$api.filter_district().then(res => {
        this.areaList = res.data.map(city => ({
          text: city.city_name,
          children: city.city_district.map((district, districtIndex) => ({ id: `${districtIndex}`, text: district }))
        }))
        this.areaList.unshift({text:'全部',children:[]})
      })
    },
    getProfileCampus(){
      this.$api.profile_campus().then(res => {
        localStorage.setItem('profile_employee',res.data.employee)
      })
    },
    onArea() {
      this.showPicker = !this.showPicker
    },
    onClickNav(data){
      if(data == 0){
        this.area = '全部'
        storage.set('wedots_area', '全部')
        this.onArea()
        this.pageCurrent = 1
        this.onPage()
      }
    },
    onAreaClicked(data) {
      this.area = data.text
      storage.set('wedots_area', data.text)
      this.onArea()
      this.pageCurrent = 1
      this.onPage()
    }
  }
}
</script>
<style lang="less" scoped>
.home-swiper,
.home-swiper img {
  width: 100%;
  height: 188px;
}
.common-h2 {
  background: none;
}
.search {
  margin-bottom: 10px;
}
.list {
  &-item {
    box-sizing: border-box;
    background: #fff;
    padding: 10px 10px;
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    margin-bottom: 10px;
    &-media {
      margin-right: 15px;
    }
    &-img {
      width: 75px;
      height: 75px;
    }
    &-inner {
      width: 0;
      flex: 1;
    }
    &-title {
      font-size: 14px;
      color: #000;
      margin-bottom: 2px;
    }
    &-desc {
      font-size: 12px;
      color: #333;
      margin-bottom: 5px;
      display: flex;
      align-items: flex-start;
      justify-items: flex-start;

      &-hd{
        flex: 1;
        &-span{
          // width: 190px;
        }
      }

      &-bd{
        // margin-left: 10px;
      }
    }
    &-rate {
      margin-bottom: 5px;
    }
    &-txt {
      font-size: 10px;
      color: #999;
    }
    &-more {
      font-size: 10px;
      &-p {
        margin-bottom: 5px;
      }
      &-tag {
        display: inline-block;
        padding: 0 2px;
        margin-right: 5px;
        color: #fff;
        background: #ff993e;
      }
    }
  }
}

  .area{
    display: flex;
    align-items: center;
  }
</style>
